import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './index.css' // Allow for gradient on all pages
import HomePage from './Pages/HomePage';
import TvPage from './Pages/TVPage';
import LoginPage from './Pages/LoginPage'
import FrontPage from './Pages/FrontPage'
import MoviePage from "./Pages/MoviePage";
import Footer from './Components/ui/Footer'
import {Toaster} from "react-hot-toast";
import { Analytics } from '@vercel/analytics/react';

const App: React.FC = () => {
    return (
        <>
            <Toaster/>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path='/home' element={<FrontPage/>}/>
                    <Route path="/tv/:param" element={<TvPage/>}/>
                    <Route path="/film/:param" element={<MoviePage/>}/>
                </Routes>
            </Router>
            <Footer/>
            <Analytics/>
        </>
    );
};

export default App;